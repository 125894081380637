import { gql } from '@apollo/client';

export const GET_PROFILES = gql`
  query getProfiles(
    $customer: ID!
    $needsAuthorization: Boolean
    $active: Boolean
  ) {
    profilesCustomer(
      customer: $customer
      needsAuthorization: $needsAuthorization
      active: $active
    ) {
      _id
      name
      isDefaultProfile
      needsAuthorization
      limit_vouchers_a_day
      limit_vouchers_r_day
      limit_vouchers_a_30_day
      limit_vouchers_r_30_day
      allow_vouchers_a_and_r_day
      limit_reais_a_day
      limit_reais_a_month
      createdAt
      updatedAt
      active
      qtyActiveClients
      user {
        _id
        name
      }
      userUpdate {
        _id
        name
      }
    }
  }
`;

export const GET_PROFILES_SELECT = gql`
  query getProfilesSelect($customer: ID!) {
    profilesCustomer(customer: $customer, active: true) {
      _id
      name
      isDefaultProfile
    }
  }
`;

export const GET_PROFILE_ID = gql`
  query getProfile($_id: ID!) {
    profile(_id: $_id) {
      _id
      name
      isDefaultProfile
      needsAuthorization
      limit_vouchers_a_day
      limit_vouchers_r_day
      limit_vouchers_a_30_day
      limit_vouchers_r_30_day
      allow_vouchers_a_and_r_day
      limit_reais_a_day
      limit_reais_a_month
      noChoice
      createdAt
      updatedAt
      active
      user {
        _id
        name
      }
      userUpdate {
        _id
        name
      }
    }
  }
`;

export const ADD_PROFILE = gql`
  mutation addProfile(
    $name: String!
    $customer: ID!
    $needsAuthorization: Boolean
    $noChoice: Boolean
    $limit_vouchers_a_day: Int
    $limit_vouchers_r_day: Int
    $limit_vouchers_a_30_day: Int
    $limit_vouchers_r_30_day: Int
    $allow_vouchers_a_and_r_day: Boolean
    $limit_reais_a_day: Float
    $limit_reais_a_month: Float
    $user: ID
  ) {
    addProfile(
      name: $name
      customer: $customer
      needsAuthorization: $needsAuthorization
      noChoice: $noChoice
      limit_vouchers_a_day: $limit_vouchers_a_day
      limit_vouchers_r_day: $limit_vouchers_r_day
      limit_vouchers_a_30_day: $limit_vouchers_a_30_day
      limit_vouchers_r_30_day: $limit_vouchers_r_30_day
      allow_vouchers_a_and_r_day: $allow_vouchers_a_and_r_day
      limit_reais_a_day: $limit_reais_a_day
      limit_reais_a_month: $limit_reais_a_month
      user: $user
    ) {
      _id
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $_id: ID!
    $name: String!
    $customer: ID!
    $needsAuthorization: Boolean
    $limit_vouchers_a_day: Int
    $limit_vouchers_r_day: Int
    $limit_vouchers_a_30_day: Int
    $limit_vouchers_r_30_day: Int
    $allow_vouchers_a_and_r_day: Boolean
    $limit_reais_a_day: Float
    $limit_reais_a_month: Float
    $noChoice: Boolean
    $user: ID
  ) {
    updateProfile(
      _id: $_id
      name: $name
      customer: $customer
      needsAuthorization: $needsAuthorization
      limit_vouchers_a_day: $limit_vouchers_a_day
      limit_vouchers_r_day: $limit_vouchers_r_day
      limit_vouchers_a_30_day: $limit_vouchers_a_30_day
      limit_vouchers_r_30_day: $limit_vouchers_r_30_day
      allow_vouchers_a_and_r_day: $allow_vouchers_a_and_r_day
      limit_reais_a_day: $limit_reais_a_day
      limit_reais_a_month: $limit_reais_a_month
      user: $user
      noChoice: $noChoice
    ) {
      success
      message
    }
  }
`;

export const INACTIVATE_PROFILE = gql`
  mutation inactivateProfile($_id: ID!) {
    inactivateProfile(_id: $_id) {
      success
      message
    }
  }
`;

export const ACTIVATE_PROFILE = gql`
  mutation activateProfile($_id: ID!) {
    activateProfile(_id: $_id) {
      success
      message
    }
  }
`;
