/**
 * Retorna "white" ou "black", de acordo com o contraste da cor em Hexadecimal
 * passada como parâmetro
 * @param {*} hexcolor
 * @returns "white" ou "black"
 */
export function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

/**
 * Converte uma string qualquer para uma cor única em Hexadecimal
 * @param {string} str String a converter
 * @returns cor em hexadecimal. Ex.: #ADF723
 */
export function stringToColour(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
