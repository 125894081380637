import styled from 'styled-components';

export const AddButtonContainer = styled.div`
  flex-direction: row;
  justify-content: center;
`;

export const AddButtonLabel = styled.span`
  font-weight: bolder;
  margin-left: 8px;
`;

export const IconDefaultProfileContainer = styled.span`
  background-color: ${props => props.bgColor};
  color: white;
  width: 32px;
  height: 32px;
  padding: 3px;
  border-radius: 4px;
`;

export const DefaultProfileLabel = styled.span`
  margin-left: 8px;
`;
