import React, { useState, useEffect } from 'react';
import { Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';

import {
  AddButtonContainer,
  AddButtonLabel,
  DefaultProfileLabel,
  IconDefaultProfileContainer
} from './styles';
import { GET_PROFILES_SELECT } from '../../../store/queries/profile';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getContrastYIQ,
  stringToColour
} from '../../../helpers/colorGenerator';

const formatOptionLabel = ({ label, value, isDefaultProfile }) =>
  value === 'add' ? (
    <AddButtonContainer>
      <i className='fas fa-plus'></i>
      <AddButtonLabel>{label}</AddButtonLabel>
    </AddButtonContainer>
  ) : isDefaultProfile ? (
    <span>{label}</span>
  ) : (
    <>
      <IconDefaultProfileContainer bgColor={stringToColour(label)}>
        <i
          className='fas fa-address-card'
          style={{ color: getContrastYIQ(stringToColour(label)) }}
        ></i>
      </IconDefaultProfileContainer>
      <DefaultProfileLabel>{label}</DefaultProfileLabel>
    </>
  );

const SelectProfile = ({
  user,
  label,
  placeholder,
  value,
  isMulti,
  disable,
  parentCallback,
  selectAllOption,
  showAddButton = false,
  isClearable = false
}) => {
  const [options, setOptions] = useState([]);
  const [getProfiles, { data, loading, error }] = useLazyQuery(
    GET_PROFILES_SELECT,
    {
      variables: {
        customer: user.customer,
        active: true
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (user && user.customer) {
      getProfiles();
    }
  }, [user]);

  useEffect(() => {
    if (data && data.profilesCustomer && !error) {
      const profiles = data.profilesCustomer.map(item => ({
        value: item._id,
        label: item.name,
        isDefaultProfile: item.isDefaultProfile
      }));

      if (showAddButton) {
        profiles.push({
          value: 'add',
          label: 'Cadastrar novo Perfil'
        });
      }

      setOptions(profiles);
    }
  }, [data]);

  function handleChange(e) {
    if (e && e.length) {
      parentCallback(e.filter(x => x.value !== 'add'));
      return;
    }

    if (e && e.value === 'add') {
      window.open(
        '/perfil-de-cliente/cadastrar',
        '_blank' // <- This is what makes it open in a new window.
      );
      return;
    }

    if (e) {
      parentCallback(e);
      return;
    }

    parentCallback(null);
  }

  return (
    <FormGroup>
      <Label> {label} </Label>
      <Select
        value={value}
        isSearchable={true}
        isDisabled={disable}
        isLoading={loading}
        onChange={e => handleChange(e)}
        placeholder={loading ? 'Buscando Perfis...' : placeholder || label}
        options={options}
        isMulti={isMulti}
        closeMenuOnSelect={isMulti ? false : true}
        isClearable={isClearable}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
        onMenuOpen={getProfiles}
        noOptionsMessage={() => 'Não há Perfis'}
        formatOptionLabel={formatOptionLabel}
      />
    </FormGroup>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SelectProfile);
