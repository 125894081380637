import React from 'react';
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button
} from 'reactstrap';

export const PopoverContainer = ({
  children,
  id,
  title = 'Ajuda',
  width = null
}) => {
  return (
    <UncontrolledPopover trigger='legacy' placement='bottom' target={id}>
      <PopoverHeader>{title}</PopoverHeader>
      <PopoverBody>{children}</PopoverBody>
    </UncontrolledPopover>
  );
};

export const PopoverButton = ({ id, title = 'Ajuda', size = 20}) => (
  <Button
    id={id}
    title={title}
    type='button'
    className='text-info btn'
    style={{
      fontSize: size,
      marginBottom: -10,
      marginTop: -10,
      padding: 0,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none'
    }}
  >
    <i className='fas fa-fw fa-question-circle'></i>
  </Button>
);
